html, body {
  background: $surface;
  color: $onSurface;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.badge {
  width: 50%;
  margin-bottom: 16px;
  position: center;
}

img {
  width: 75%;
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

h2 {
  font-weight: 600;
  font-size: 1.4em;
  margin: 0 0;
  text-align: center;
}

h1 {
  font-weight: 600;
  font-size: 1.6em;
  text-align: center;
}

p {
  color: $onSurfaceVariant;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-size: 1em;
  text-align: center;
  margin-bottom: 24px;
}

@media #{$desktop} {
  .flex {
    @include flexbox;
    align-items: center;
    flex-direction: row;

    .text, .image {
      -webkit-flex: 1;
      flex: 1;
      padding: 0 0;
    }
  }

  .content section:nth-child(odd) .flex {
    flex-direction: row-reverse;
  }
}
