.content {
  height: 100%;
  position: relative;
}

.container {
  margin: 0 auto;
  position: relative;
  padding: 0 24px;
}

.container {
  height: 100%;
  max-width: 1140px;

  &.max-container {
    max-width: 100%;
    padding: 0;
  }
}

header {
  padding: 16px 0 0;
  background: $surface;

  a {
    color: $onSurface;
    font-weight: 600;
    font-size: 1.2em;
    text-decoration: none;
    z-index: 1;
    position: relative;

    &:hover {
      text-decoration: none;
    }
  }
}

section {
  display: block;
  height: 100%;
  padding: 0 0;
}
